import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Link as RouterLink, withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import ROUTES from 'seller/routing';
import GenerateInputs from '@components/inputs/GenerateInputs';
// import Colors from '../../../../styles/colors';
import Logo from '@components/common/Logo';
import { addInputFocusHandler } from '@utils';
import { ImageObserver } from 'seller/containers/observer';
import { EnvelopeIcon, LockIcon /* FacebookIcon, GoogleIcon */ } from '@components';
import CheckBox from '@components/check-box';
import ProTipsView from '@components/pro-tips-view';
import { useTextInput } from '../profile/Hooks';
import { loginAction, setWarningMsgAction } from '../../store/actions';
import { getShopifyCallbackData } from '../../store/actions/shopify.actions';

function LoginWindow({ login, token, username, history, location, onLoginPath, setMessage }) {
  const [checkbox, setCheckbox] = React.useState(false);
  const email = useTextInput(username);
  const password = useTextInput('');
  const inputRefs = useRef([]);

  function onLoginClick() {
    if (!email.value || !password.value) {
      return;
    }
    login(email.value, password.value, () => history.push(`${onLoginPath}${location.search}`));
  }

  const inputsList = [
    { icon: EnvelopeIcon, type: 'email', props: email },
    { icon: LockIcon, type: 'password', props: password }
  ];

  addInputFocusHandler(inputsList, onLoginClick, inputRefs);
  useEffect(() => {
    if (token && username) history.push(`${onLoginPath}${location.search}`);
    if (!getShopifyCallbackData(location)) return;
    setMessage('Please login for Shopify app installation');
  }, [history, location, onLoginPath, setMessage, token, username]);

  return (
    <ImageObserver className="flex bg white w-full sm-full-min-h-mobile">
      <div className="flex flex-col items-center justify-center bg-white md:w-2/4 w-full box-shadow-default min-h-screen z-0">
        <div className="flex flex-col items-center w-10/12 sm:sm-w-300px">
          <Logo className="w-2/3 sm:sm-w-160px" to={ROUTES.root} />
          <p className="text-2xl font-bold my-10 mr-auto text-black">Login to Seller Metrix</p>
          <form className="w-full">
            <GenerateInputs data={inputsList} />
            <CheckBox
              label={<span className="text-lg md:text-base">Keep me logged in</span>}
              classNameContainer="mt-2"
              classNameCheckBox="rounded-sm rounded h-5 w-5 md:w-4 md:h-4"
              value={checkbox}
              onCheckHandler={check => setCheckbox(check)}
            />
            <Button
              variant="contained"
              className="w-full p-2 mt-4 bg-grad-2 text-white capitalize text-sm"
              onClick={onLoginClick}
            >
              Login
            </Button>
          </form>
          <Link
            component={RouterLink}
            to={ROUTES.resetPassword}
            color="inherit"
            className="text-sm text-gray-dark my-4 font-medium"
          >
            Forgot my Password
          </Link>
          <div className="text-sm text-gray-dark font-medium">
            <p className="mr-1 inline-block">Dont have an account yet?</p>
            <Link
              component={RouterLink}
              to={`${ROUTES.registration}${location.search}`}
              color="inherit"
              className="text-yellow"
            >
              Sign Up
            </Link>
          </div>
          {/*
          <p className="text-xs text-gray-dark my-4 font-medium">or</p>

          FIXME: back end is not ready now
          <Button
            variant="contained"
            className="w-full p-2 text-white flex justify-between items-center capitalize bg-socialnetwork-facebook"
          >
            <FacebookIcon fill={Colors.white.default} height="1.125rem" />
            <p>Continue with Facebook</p>
            <div />
          </Button>
          <Button
            variant="contained"
            className="w-full p-2 text-white flex justify-between items-center capitalize mt-4 bg-socialnetwork-google"
          >
            <GoogleIcon fill={Colors.white.default} />
            <p>Continue with Google</p>
            <div />
          </Button>
          */}
        </div>
      </div>
      <div className="w-2/4 flex justify-center items-center bg-white-blue hidden md:flex ">
        <ProTipsView />
      </div>
    </ImageObserver>
  );
}

LoginWindow.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  login: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  setMessage: PropTypes.func.isRequired,
  username: PropTypes.string,
  onLoginPath: PropTypes.string
};

LoginWindow.defaultProps = {
  username: null,
  onLoginPath: ROUTES.home
};

function mapStateToProps(state) {
  return {
    username: state.login.username,
    token: state.login.token
  };
}

export default withRouter(
  connect(mapStateToProps, {
    login: loginAction,
    setMessage: msg => dispatch => dispatch(setWarningMsgAction(msg, false, false))
  })(LoginWindow)
);
