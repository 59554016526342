import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { APP_MENU_ITEMS } from 'seller/appList';
import LoadingSpinner from '@components/common/LoadingSpinner';
import { ACTIVE_USER_STATUSES, isInactive, isIncompleted, isOnboarding } from '@sm/api';
import { createRange } from '@utils';
import { NoOrdersDialog } from '@components';
import { loadUserProfile as loadData, logoutAction, setApp as setAppAction } from './store/actions';
import ROUTES, { AGENCY_ALLOWED_ROUTES, REGULAR_USER_ALLOWED_ROUTES } from './routing';
import { addShopifyShop } from './store/actions/shopify.actions';

class Auth extends Component {
  componentDidMount() {
    const {
      profile,
      loadUserProfile,
      match,
      setApp,
      history,
      location,
      permittedState
    } = this.props;

    const currentApp = APP_MENU_ITEMS.find(item => match?.url?.includes(item.link));
    if (currentApp) setApp(currentApp.title);

    if (profile.userName) return;

    const agencyClientUserId = sessionStorage.getItem('userId') || localStorage.getItem('userId');
    const agencyClientFrom = sessionStorage.getItem('from');
    const agencyClientTo = sessionStorage.getItem('to');
    let agencyDateRange = null;

    if (agencyClientFrom && agencyClientTo) {
      agencyDateRange = createRange(new Date(agencyClientFrom), new Date(agencyClientTo));
    }

    addShopifyShop(location, history).then(skipLoadProfile => {
      if (skipLoadProfile) return;
      loadUserProfile(
        agencyClientUserId,
        loadedProfile => {
          if (permittedState && permittedState === loadedProfile.status) {
            return;
          }
          if (isOnboarding(loadedProfile) && match.url !== ROUTES.onboarding) {
            history.push(ROUTES.onboarding);
          }
          if (isIncompleted(loadedProfile) && match.url !== ROUTES.welcome) {
            history.push(ROUTES.welcome);
          }

          if (isInactive(loadedProfile) && match.url !== ROUTES.plans) {
            history.push(ROUTES.plans);
          }
        },
        serverUnavailable => {
          history.push(serverUnavailable ? ROUTES.maintenance : ROUTES.login);
        },
        agencyDateRange,
        !!agencyClientUserId
      );
    });
  }

  render() {
    const {
      history,
      profile,
      children,
      permittedState,
      match,
      checkForNoOrders,
      onLogout,
      login
    } = this.props;
    if (!login.token) {
      history.push(ROUTES.login);
      onLogout();
    }

    if (permittedState && permittedState === profile.status) {
      return <>{children}</>;
    }

    if (isIncompleted(profile) && match.url !== ROUTES.welcome) {
      history.push(ROUTES.welcome);
      return <LoadingSpinner />;
    }

    if (
      ACTIVE_USER_STATUSES.find(item => item === profile.status) &&
      match.url === ROUTES.welcome
    ) {
      history.push(ROUTES.home);
      return <LoadingSpinner />;
    }

    if (isInactive(profile) && match.url !== ROUTES.plans) {
      history.push(ROUTES.plans);
      return <LoadingSpinner />;
    }

    if (!profile.userName) {
      return <LoadingSpinner />;
    }

    if (match.url === ROUTES.welcome) {
      return <>{children}</>;
    }

    if (isOnboarding(profile) && match.url !== ROUTES.onboarding) {
      history.push(ROUTES.onboarding);
      return <LoadingSpinner />;
    }
    if (
      (profile.agency && !AGENCY_ALLOWED_ROUTES.find(route => match.url?.includes(route))) ||
      (!profile.agency && !REGULAR_USER_ALLOWED_ROUTES.find(route => match.url?.includes(route)))
    ) {
      history.push(ROUTES.notFound);
      return <LoadingSpinner />;
    }

    return (
      <>
        {checkForNoOrders && <NoOrdersDialog open={profile.no_marketplaces} />}
        {children}
      </>
    );
  }
}

Auth.propTypes = {
  profile: PropTypes.instanceOf(Object).isRequired,
  login: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.node.isRequired,
  loadUserProfile: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  setApp: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  permittedState: PropTypes.string,
  checkForNoOrders: PropTypes.bool,
  marketplaces: PropTypes.instanceOf(Array)
};

Auth.defaultProps = {
  permittedState: null,
  checkForNoOrders: false,
  marketplaces: []
};

function mapStateToProps(state) {
  return {
    profile: state.user.profile,
    login: state.login,
    app: state.app.app,
    marketplaces: state.user.profile.marketplaces
  };
}

export default withRouter(
  connect(mapStateToProps, {
    loadUserProfile: loadData,
    setApp: setAppAction,
    onLogout: logoutAction
  })(Auth)
);
