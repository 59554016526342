import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
// import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import ReCAPTCHA from "react-google-recaptcha";

// import Colors from 'styles/colors';
import GenerateInputs from '@components/inputs/GenerateInputs';
import { GradButton } from '@components/buttons';
import NewTabReference from '@components/common/NewTabReference';
import Logo from '@components/common/Logo';
import { ENTER_CHAR_CODE, SPACE_CHAR_CODE, addInputFocusHandler } from '@utils';
import { ImageObserver } from 'seller/containers/observer';
import { EnvelopeIcon, LockIcon, UserIcon } from '@components';
import CheckBox from '@components/check-box';
import ProTipsView from '@components/pro-tips-view';
import ArrowIcon from '@components/icons/ic_arrow_left.svg';
import { useTextInput } from '../profile/Hooks';
import { signupAction } from '../../store/actions';
import ROUTES from '../../routing';

function Registration({ signup, history, location }) {
  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const recaptchaRef = React.useRef();
  const [captchaToken, setToken] = React.useState("");
  const searchParams = new URLSearchParams(location.search);
  const promo = searchParams.get('promo');

  const handleChange = value => {
    setToken(value);
  };
  const [checkbox, setCheckbox] = React.useState(false);

  const userName = useTextInput('');
  const email = useTextInput('');
  const password = useTextInput('');

  const inputRefs = useRef([]);

  const formFilled = userName.value && email.value && password.value && checkbox;

  const onRegistration = () => {
    if (!formFilled) return;
    if (!email.value || !password.value || !checkbox || (RECAPTCHA_SITE_KEY && !captchaToken)) {
      return;
    }

    const onFailedSignup = () => {
      if (!RECAPTCHA_SITE_KEY) return;

      setToken("")
      recaptchaRef.current.reset()
    }

    signup(userName.value, email.value, password.value, captchaToken, promo, () => {
      history.push(`${ROUTES.welcome}${location.search}`);
    }, onFailedSignup);
  };

  const inputsList = [
    { icon: UserIcon, label: 'Name', props: userName },
    { icon: EnvelopeIcon, label: 'email', props: email },
    {
      icon: LockIcon,
      type: 'password',
      props: { ...password, inputProps: { autoComplete: 'new-password' } }
    },
    { props: {} }
  ];

  addInputFocusHandler(inputsList, onRegistration, inputRefs);

  const checkBoxData = inputsList.pop();

  const TermsContent = (
    <span className="text-lg md:text-base">
      I agree with the
      <NewTabReference
        className="text-yellow hover:underline ml-1"
        href="https://sellermetrix.com/terms-of-service/"
      >
        Terms & Conditions
      </NewTabReference>
    </span>
  );

  const recaptchaView = RECAPTCHA_SITE_KEY ? (
    <ReCAPTCHA
    ref={recaptchaRef}
    sitekey={RECAPTCHA_SITE_KEY}
    onChange={handleChange} 
  />
  ) : null;

  return (
    <ImageObserver className="flex bg white w-full h-full">
      <div className="flex flex-col items-center justify-center bg-white md:w-2/4 w-full box-shadow-default min-h-screen z-0">
        <div className="flex flex-col items-center w-10/12 sm:sm-w-300px">
          <Logo className="w-2/3 sm:sm-w-160px" to={ROUTES.root} />
          <p className="text-sm text-gray-dark mt-4">Start your free trial</p>
          <div className="flex items-center w-full -ml-3 mt-16 mb-8">
            <Link to={`${ROUTES.login}${location.search}`}>
              <IconButton>
                <img src={ArrowIcon} alt="arrow-left" className="h-4 w-4" />
              </IconButton>
            </Link>
            <p className="text-2xl font-bold  mr-auto text-black">Register</p>
          </div>
          <form className="w-full">
            <GenerateInputs data={inputsList} />
            <CheckBox
              label={TermsContent}
              classNameContainer="mt-2"
              classNameCheckBox="rounded-sm rounded h-5 w-5 md:w-4 md:h-4"
              value={checkbox}
              onCheckHandler={check => setCheckbox(check)}
              onKeyPress={e => {
                if (e.charCode === SPACE_CHAR_CODE) {
                  setCheckbox(!checkbox);
                } else if (e.charCode === ENTER_CHAR_CODE) {
                  checkBoxData.props.inputProps.onKeyPress({
                    charCode: ENTER_CHAR_CODE,
                    currentTarget: {
                      attributes: { inputId: { nodeValue: checkBoxData.props.inputProps.inputId } }
                    }
                  });
                }
              }}
              ref={checkBoxData.props.inputRef}
            />
            <GradButton
              variant="contained"
              className="w-full p-2 my-4 h-10 text-white"
              onClick={onRegistration}
              active={formFilled}
            >
              Register
            </GradButton>
            {recaptchaView}
          </form>
          {/*
          FIXME: not ready for now
          <p className="text-xs text-gray-dark my-4 font-medium">or</p>
          <Button
            variant="contained"
            className="w-full p-2 text-white flex justify-between items-center capitalize bg-socialnetwork-facebook"
          >
            <FacebookIcon fill={Colors.white.default} height="18px" />
            <p>Continue with Facebook</p>
            <div />
          </Button>
          <Button
            variant="contained"
            className="w-full p-2 text-white flex justify-between items-center capitalize mt-4 bg-socialnetwork-google"
          >
            <GoogleIcon fill={Colors.white.default} />
            <p>Continue with Google</p>
            <div />
          </Button>
          */}
        </div>
      </div>
      <div className="w-2/4 flex justify-center items-center bg-white-blue hidden md:flex">
        <ProTipsView />
      </div>
    </ImageObserver>
  );
}

Registration.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  signup: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    username: state.login.username
  };
}

export default connect(mapStateToProps, {
  signup: signupAction
})(Registration);
